<template>
  <div :class="`omniview-map omniview-map-${unit_id}`"></div>
</template>

<script>
import { Gps as OMS } from "@omnicloud/maps-sdk";
import store from "@/store";
import { SLOT_TYPES } from "@/config/layoutConfig";
import layoutMixin from "@/mixins/layoutMixin";
import { EventBus } from "@/libs/event-bus";
import useAppConfig from '@core/app-config/useAppConfig'

const token = window.localStorage.accessToken;
const mapGps = new OMS({
  token,
  apiUrl: `${process.env.VUE_APP_BASE_URL}/v1`,
  mode: 'units'
});

export default {
  mixins: [layoutMixin],
  props: {
    unit_id: {
      type: String | Number,
    },
    data: {
      type: Number,
    },
  },
  data() {
    return {
      mapSelector: null,
      SLOT_TYPES,
    };
  },
  computed: {
    units() {
      return store.state.grid.units;
    },
  },

  async mounted() {
    const selector = `.omniview-map-${this.unit_id}`;
    this.mapSelector = document.querySelector(selector);

    const { skin } = useAppConfig()

    mapGps.setConfig({
      container: selector,
      latLng: { lat: 19.39068, lng: -99.2836975 },
      gmapsKey: process.env.VUE_APP_GMAPS_KEY,
      triggerEvents: true,
      darkMode: skin.value == "dark" ? true : false
    });

    EventBus.on(`toogle-dark-mode`, (params) => {
      if (params == 'dark') {
        mapGps.setDarkMode();
      } else {
        mapGps.setLightMode();
      }
    });

    mapGps.onMapReady().then(async () => {
      mapGps.setAlarm(this.data).then((data) => {
        mapGps.setMarkers(data.data);
        if (data.data.length > 0) {
          mapGps.setCenter(
            data.data[data.data.length - 1].lat,
            data.data[data.data.length - 1].lng,
            15
          );
        }
      });

      window.Echo.private(`gps-location-alarm-${this.data}`).listen(".create", (dat) => {
        mapGps.setAlarm(this.data).then((data) => {
          mapGps.setMarkers(data.data);
          if (data.data.length > 0) {
            mapGps.setCenter(
              data.data[data.data.length - 1].lat,
              data.data[data.data.length - 1].lng,
              15
            );
          }
        });
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.omniview-map {
  width: 100%;
  height: 100%;
}
</style>
