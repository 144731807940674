import { ref, computed, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import dateParser from "@/libs/date-parser";
import { debounce } from 'lodash'

export default function useList() {
  const { t } = useI18nUtils();
  const trans = {
    'messages.error.server_error': t('messages.error.server_error'),
    "messages.export.download": t("messages.export.download"),
    "messages.export.Generating_file": t("messages.export.Generating_file"),
  };
  const refListTable = ref(null)

  const toast = useToast()

  const loadingToast = ref(null);
  const action = ref('list')
  const disableExport = ref(false)

  const tableColumns = [
    { label: 'vxg_camera.name', key: 'name', sortable: true, stickyColumn: true },
    {
      label: 'date.created_at', key: 'created_at', sortable: true,
      formatter: (value, key, item) => {
        return dateParser.parseDate(value)
      },
      thStyle: 'min-width: 215px'
    },
    {
      label: 'date.updated_at', key: 'updated_at', sortable: true,
      formatter: (value, key, item) => {
        return dateParser.parseDate(value)
      },
      thStyle: 'min-width: 215px'
    },
  ]

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const initFilters = () => {
    store.dispatch('sector/getAll')
    store.dispatch('center/getAll')
    store.dispatch('tenant/getAll')
    store.dispatch('unit/getAll', { with: 'router:id,id_tenant' })
    store.dispatch('camera_type/getAll')
    store.dispatch('vxg_master_account/getAll')
  }

  const sectorOptions = computed(() => store.getters['sector/getSelectedItems'])
  const sectorFilter = ref(null)

  if (router.currentRoute.params.sectorFilter)
    sectorFilter.value = parseInt(vxg_camera.currentRoute.id_sector)

  const centerOptions = computed(() => store.getters['center/getSelectedItems'])
  const centerFilter = ref(null)

  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)

  const unitOptions = computed(() => store.getters['unit/getSelectedItems'])

  const cameraTypeOptions = computed(() => store.getters['camera_type/getSelectedItems'])

  const vxgMasterAccountOptions = computed(() => store.getters['vxg_master_account/getSelectedItems'])
  const vxgMasterAccountFilter = ref(null)

  const onlineFilter = ref(null)
  const rtspAvailableFilter = ref(null)

  const unitsFilter = ref(null)

  if (router.currentRoute.params.centerFilter)
    centerFilter.value = parseInt(vxg_camera.currentRoute.id_center)

  const fetchList = (ctx, callback) => {
    if (action.value == 'export') {
      loadingToast.value.show(
        trans["messages.export.download"],
        trans["messages.export.Generating_file"],
        "secondary"
      );
    }
    store.dispatch('camera_proxy/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      units: unitsFilter.value,
      online: onlineFilter.value,
      tenant: tenantFilter.value,
      action: action.value,
      // rtsp_available: rtspAvailableFilter.value,
    })
    .then(response => {
      if (action.value == 'export') {
        action.value = 'list'
        disableExport.value = false
        loadingToast.value.close();
      } else {
        callback(response.data)
        totalList.value = response.meta.total
      }
    })
    .catch((error) => {
      console.log(error)
      action.value = 'list'
      disableExport.value = false
      loadingToast.value.close();
      toast({
        component: ToastificationContent,
        props: {
          title: trans['messages.error.server_error'],
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([vxgMasterAccountFilter, tenantFilter, currentPage, perPage, sectorFilter, centerFilter, onlineFilter, rtspAvailableFilter], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  const applyChartFilters = (filters) => {
    if (filters) {
      onlineFilter.value = filters['online'] || null;
      unitsFilter.value = filters['units'] || null;
      tenantFilter.value = filters['id_tenant'] || null;
    }
  }

  return {
    tenantOptions,
    tenantFilter,
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    sectorOptions,
    sectorFilter,
    centerOptions,
    centerFilter,
    unitOptions,
    cameraTypeOptions,
    vxgMasterAccountOptions,
    vxgMasterAccountFilter,
    onlineFilter,
    rtspAvailableFilter,
    refetchData,
    applyChartFilters,
    unitsFilter,

    initFilters,

    action,
    disableExport,
    loadingToast,
  }
}
